import React from 'react';
import {
  MDBSideNavLink,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon,
  MDBSelect,
  MDBLink,
  MDBBtn,
  MDBSideNavCat
} from 'mdbreact';
import { connect } from 'react-redux';
import userActions from '../actions/userActions';
import Logo from '../assets/images/logoTop.svg' // relative path to image

import VacationMenuLink from './modules/vacation/VacationMenuLink';
import NewsMenuLink from './modules/news/NewsMenuLink';

import PermissionHelper from "../helpers/PermissionHelper";

import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'


class SideNavigation extends React.Component {

  constructor(props) {
    super(props);

    //console.log(props);
    this.state = {
      isOpen: false,
      locationOptions: this.props.locations,
      selectedLocation: localStorage.getItem('selectedLocation'),
      selectedLocationOption: false,
      buttonStyle: {
        transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
        opacity: "0"
      }
    }

    this.locationSelect = React.createRef();

    this._onLocationChange = this._onLocationChange.bind(this);
  }

  componentDidMount() {
    var component = this;
    //console.log(this);
    //console.log(localStorage.getItem('selectedLocation'));

    var locations = this.props.locations;
    var locationOptions = [];
    //console.log(locations);
    var selectedLocationOption = false;

    if(this.props.locations) {
      locations.forEach(function(item){
        var optionItem = {
          label: item.title[0].value,
          value: item.nid[0].value.toString()
        }
        var selectedLocation = localStorage.getItem('selectedLocation');



        if(selectedLocation == item.nid[0].value.toString()) {

          //console.log(selectedLocation);
          //console.log(item.nid[0].value.toString());

          selectedLocationOption = {
            label: item.title[0].value,
            value: item.nid[0].value.toString()
          }

          //console.log(selectedLocationOption);

          component.setState({
            selectedLocationOption: selectedLocationOption
          });
        }
        locationOptions.push(optionItem);
        //console.log(optionItem);
      });

      this.setState({
        locationOptions: locationOptions,
      });
    }
  }

  componentWillUnmount() {
  }

  _onLocationChange(value) {
    //console.log(value);
    if(value != this.state.selectedLocation && value && value != '') {
      this.setState({
        selectedLocation: value
      });
      localStorage.setItem('selectedLocation', value);
      window.location.reload();
    }
  }

  _onNavigationToggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }




  render() {
    //console.log(user);
    const { onLinkClick } = this.props;
    var component = this;
    return (
      <div className={this.state.isOpen ? 'white-skin open' : 'white-skin not-open'} id="side-navigation">

        <MDBBtn onClick={(e) => this._onNavigationToggle()}>
          {!this.state.isOpen &&
            <MDBIcon icon="angle-right" />
          }
          {this.state.isOpen &&
            <MDBIcon icon="angle-left" />
          }
        </MDBBtn>

        <MDBSideNav
          fixed

        >
          <li>
            <div className="page-logo">
              <MDBLink onClick={(e) => { onLinkClick('Dashboard'); this._onNavigationToggle()}} to={`/dashboard`} className="d-inline">
                <img alt="" src={Logo} className="img-fluid" />
              </MDBLink>
            </div>
          </li>

          <MDBSideNavNav>


            <MDBSideNavLink topLevel to='/dashboard' onClick={(e) => { onLinkClick('Dashboard'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faHouse} />
              Dashboard
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/order' onClick={(e) => { onLinkClick('Bestellungen'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faList} />
              Bestellungen
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/product' onClick={(e) => { onLinkClick('Produkte'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faList} />
              Produkte
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/customer' onClick={(e) => { onLinkClick('Kunden'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faList} />
              Kunden
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/employee' onClick={(e) => { onLinkClick('Mitarbeiter'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faList} />
              Mitarbeiter
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/cashregister' onClick={(e) => { onLinkClick('Kassen'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faCashRegister} />
              Kassen
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/giftcard' onClick={(e) => { onLinkClick('Gutscheine'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faGiftCard} />
              Gutscheine
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/subscription' onClick={(e) => { onLinkClick('Abos'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faGiftCard} />
              Abos
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/evaluation' onClick={(e) => { onLinkClick('Auswertung'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faUserChart} />
              Auswertung
            </MDBSideNavLink>

            <MDBSideNavLink topLevel to='/location' onClick={onLinkClick}>
              <FontAwesomeIcon icon={AllLightIcons.faGlobeStand} />
              Standorte
            </MDBSideNavLink>

            <VacationMenuLink onClick={(e) => { onLinkClick('Ferien'); this._onNavigationToggle()}} />

            <NewsMenuLink onClick={(e) => { onLinkClick('News'); this._onNavigationToggle()}} />

            <MDBSideNavLink topLevel to='/newsletter' onClick={(e) => { onLinkClick('Newsletter'); this._onNavigationToggle()}}>
              <FontAwesomeIcon icon={AllLightIcons.faUserChart} />
              Newsletter
            </MDBSideNavLink>

          </MDBSideNavNav>


          <button className="btn btn-logout" onClick={this.props.logout}>
            <FontAwesomeIcon icon={AllLightIcons.faPowerOff} />
          </button>




        </MDBSideNav>
      </div>
    );
  }
}

function mapState(state) {
    const { authenticationReducer } = state;
    const { user, locations } = authenticationReducer;
    return { user, locations };
}

const actionCreators = {
  logout: userActions.logout
}

const connectedSideNavigation = connect(mapState, actionCreators)(SideNavigation);
export { connectedSideNavigation as SideNavigation };
